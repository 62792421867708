import * as React from "react"
import {Header} from "../components/header";
import {Footer} from "../components/footer";

const PrivacyPolicy = () => {
    return (
        <main>
            <Header/>
            <div className="page">
                <div className="container">
                    <h1>Privacy Policy for Influere Investigations</h1>
                    <br/><hr/><br/>
                    <p> Influere Corp together with its subsidiaries or affiliates and any other entity, as applicable (collectively, “Influere”, “we” or “us”) operating a website which is available at: www.influereinvestigations.com and any and all websites and applications that Influere operates or that link to these Terms, including but not limited to, any and all pages within each such website or application, any equivalent mirror, replacement, substitute or backup website or application, and pages that are associated with each such website or application, and shall govern the access, download and use of our website and any services, products or mobile applications which may be provided therein (collectively, the “Website and the “Services”, respectively) by any of our users who accesses, uses or otherwise interacts with the Website (“you” or “user(s)”). </p>
                    <p>We at Influere respect your right to privacy as detailed in this privacy policy (“Privacy Policy”). Please read and review carefully this Privacy Policy as it explains how we collect, process and use data we collect from you in connection with the Website and Services. We encourage you to read this Privacy Policy prior to accessing or using the Website or Services. Any capitalized terms not defined hereunder shall have the meaning ascribed to them under the terms of use of the Website available at <a href="https://influereinvestigations.com/privacy-policy">https://influereinvestigations.com/privacy-policy</a> (the “Terms”). This Privacy Policy is incorporated by reference into the Terms as an integral part thereof. </p>
                    <p>If you choose to access, use, register to the Website or Services or provide data to us or through the Website or Services you explicitly agree to the use of such data in accordance with this Privacy Policy. You may not use the Website or Services or submit any data through it if you do not agree to any of the terms hereunder. This Privacy Policy shall not be construed in any manner to derogate from the Terms or any other agreement made by and between Influere and you.</p>
                    <h5><u>Privacy Policy Highlights:</u></h5>
                    <ul>
                        <li>The content you upload to Website or Services is not intended to be confidential, and may be reviewed by us and our trusted third-party partners and affiliates. As such, please do not share or disclose any information which is sensitive or confidential unless you agree that any such information will be disclosed to our trusted third-party partners and affiliates. </li>
                        <li>Personally Identifiable Information is information that, when used alone or with other relevant data, can identify an individual (“Personally Identifiable Information”). By agreeing to this privacy policy you agree and understand that in the course of your use of the Website or the Services we may process Personally Identifiable information related to you or your Personally Identifiable Information.</li>
                        <li>You are not required by law to provide us with any Personally Identifiable Information. Sharing Personally Identifiable Information with us is entirely voluntary.  </li>
                        <li>Our Website and Services are intended for users over the minimum age for providing consent to processing of Personally Identifiable Information in the relevant jurisdiction. Children or minors under such age are not permitted to register to the Website or use the Services. If you are under such age, you should cease using the Website or Services immediately. </li>
                        <li>We do not sell, trade, or rent users’ Personally Identifiable Information to third parties. We only share Personally Identifiable Information with third parties in connection with the provision of the Website or Services to our users, or other limited circumstances specified in this Privacy Policy.</li>
                        <li>You may be entitled under applicable law to request to review, amend, erase or restrict the processing of your Personally Identifiable Information. Please note that in case you request to erase or restrict the processing of your Personally Identifiable Information, your use of the Website or Services may be restricted or disabled.</li>
                        <li>If you have any questions or requests regarding the processing of your Personally Identifiable Information or would otherwise like to contact us in connection with this Privacy Policy, please send us an email to: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</li>
                    </ul>
                    <br/><br/>
                    <h5><u>1. The Types of Personally Identifiable Information Influere Collects About Users; What Is Personally Identifiable Information?</u></h5>

                    <p style={{paddingLeft: '25px'}}><u>1.1. &nbsp;&nbsp;&nbsp;We have collected the following categories of Personally Identifiable Information from consumers:</u></p>
                    <p style={{paddingLeft: '50px'}}>1.1.1. &nbsp;&nbsp;&nbsp;Identifiers, which may include real name and alias; mailing address; unique personal identifier; online identifiers as detailed below; Internet Protocol (“IP”) address; email address; telephone number; account number, name, and password; Social Security number; driver’s license number, passport number, state or other government-issued identification card number; and/or other similar identifiers.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.2. &nbsp;&nbsp;&nbsp;Internet or other electronic network activity information, including, but not limited to the duration of sessions, the content uploaded and downloaded, viewed web-pages or specific content on web-pages, activity measures.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.3. &nbsp;&nbsp;&nbsp;Information relating to Internet activity or other electronic network activity, which may include cookie identifiers, clear gifs (a.k.a. web beacons/web bugs), browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, clickstream data, device Website, device version, and/or other device characteristics</p>
                    <p style={{paddingLeft: '50px'}}>1.1.4. &nbsp;&nbsp;&nbsp;Geolocation data, which may include GPS data; locational information based upon your IP address; cell network data; and/or other similar locational data; and which may be collected from various devices including your mobile device(s).</p>
                    <p style={{paddingLeft: '50px'}}>1.1.5. &nbsp;&nbsp;&nbsp;Audio, electronic, or visual information, which may include records of calls to or from our customer service centers; and/or video surveillance information.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.6. &nbsp;&nbsp;&nbsp;Professional or employment-related information, such as your current and previous employers; job title and responsibilities; assets; income; and/or other information related to your work history and/or prospective employment.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.7. &nbsp;&nbsp;&nbsp;Education information.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.8. &nbsp;&nbsp;&nbsp;Information not listed above and related to characteristics protected under California or federal law; which may include gender; race and ethnicity; nationality; marital status; military service / veteran status; and/or date of birth.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.9. &nbsp;&nbsp;&nbsp;Other personal information not listed above and described in California Civil Code § 1798.80(e), which may include signature; physical characteristics or description; insurance policy number; and/or bank account number credit card number, debit card number, and other financial, medical, and/or health insurance information.</p>
                    <p style={{paddingLeft: '50px'}}>1.1.10. &nbsp;&nbsp;&nbsp;Inferences drawn from any of information identified above to create a profile about consumers.</p>

                    <p style={{paddingLeft: '25px'}}><u>1.2. &nbsp;&nbsp;&nbsp;Our Categories of Sources for Personally Identifiable Information.</u></p>
                    <p style={{paddingLeft: '50px'}}>1.2.1. &nbsp;&nbsp;&nbsp;We obtain the categories of personally identifiable information listed above from the following categories of sources:</p>
                    <p style={{paddingLeft: '50px'}}>1.2.1. &nbsp;&nbsp;&nbsp;Users of our Website.</p>
                    <p style={{paddingLeft: '50px'}}>1.2.1. &nbsp;&nbsp;&nbsp;Things that other third parties may post publicly about you or otherwise provide to us, which may include review Websites, public forums, and testimonial websites.</p>
                    <p style={{paddingLeft: '50px'}}>1.2.1. &nbsp;&nbsp;&nbsp;Third party service providers assisting us in connection with ‘know your customer’ and anti money laundering investigations we are required to perform in accordance with applicable law.</p>

                    <p style={{paddingLeft: '25px'}}><u>1.3. &nbsp;&nbsp;&nbsp;What Do We Do with the Personally Identifiable Information We Collect?</u></p>
                    <p style={{paddingLeft: '50px'}}>1.3.1. &nbsp;&nbsp;&nbsp;We further use personally identifiable information to enable the Website and the Services, to study and analyze the functionality of the Website and the Services, to provide support, to measure the Website and the Services activity, to maintain the Website and the Services, to make the Website and the Services better, and to continue developing the Website and the Services.</p>
                    <p style={{paddingLeft: '50px'}}>1.3.1. &nbsp;&nbsp;&nbsp;If you provide us your email address, we may use it to contact you when necessary, to provide information and notices about the Website and the Services. We will include commercial and marketing information about our Website and the Services.</p>
                    <p style={{paddingLeft: '50px'}}>1.3.1. &nbsp;&nbsp;&nbsp;If necessary, we will use personally identifiable information for the following purposes:</p>
                    <p style={{paddingLeft: '100px'}}>(i) &nbsp;&nbsp;&nbsp;to enforce our Terms, this Privacy Policy and other legal agreements;</p>
                    <p style={{paddingLeft: '100px'}}>(ii) &nbsp;&nbsp;&nbsp;to comply with court orders and warrants, and assist law enforcement agencies;</p>
                    <p style={{paddingLeft: '100px'}}>(iii) &nbsp;&nbsp;&nbsp;to collect debts, prevent fraud, misappropriation, infringements, identity thefts and any other misuse of the Website and the Services;</p>
                    <p style={{paddingLeft: '100px'}}>(iv) &nbsp;&nbsp;&nbsp;to prevent cyber security attacks and any other unlawful or unauthorized use of your personal information and the Website and the Services;</p>
                    <p style={{paddingLeft: '100px'}}>(v) &nbsp;&nbsp;&nbsp;to take any action in any legal dispute and proceeding; and</p>
                    <p style={{paddingLeft: '100px'}}>(vi) &nbsp;&nbsp;&nbsp;for additional purposes which are compatible with the purposes listed under this policy and that we believe in good-faith to be within your reasonable expectation.</p>

                    <p style={{paddingLeft: '25px'}}><u>1.4. &nbsp;&nbsp;&nbsp;Our Use and Disclosure Practices.</u></p>
                    <p style={{paddingLeft: '50px'}}>1.4.1. &nbsp;&nbsp;&nbsp;We use the personally identifiable information that we collect or receive for the business purposes as described above under the section titled “What Do We Do with Personally Identifiable Information?” of this Privacy Policy.</p>
                    <p style={{paddingLeft: '50px'}}>1.4.2. &nbsp;&nbsp;&nbsp;We disclose personally identifiable information to third parties for business purposes as described above under the section titled “Sharing Data with Third Parties” in this policy.</p>
                    <p style={{paddingLeft: '50px'}}>1.4.3. &nbsp;&nbsp;&nbsp;In the preceding twelve (12) months, we have disclosed the following categories of personally identifiable information for business purposes:</p>
                    <p style={{paddingLeft: '100px'}}>(i) &nbsp;&nbsp;&nbsp;Identifiers.</p>
                    <p style={{paddingLeft: '100px'}}>(ii) &nbsp;&nbsp;&nbsp;Internet or other electronic network activity information.</p>
                    <p style={{paddingLeft: '100px'}}>(iii) &nbsp;&nbsp;&nbsp;Inferences drawn from any of information identified above.</p>
                    <br/><br/>
                    <h5><u>2. Tracking Technologies</u></h5>
                    <p style={{paddingLeft: '25px'}}>1.5. &nbsp;&nbsp;&nbsp;When you use the Website or Services, we may use tracking technologies known as cookies or web beacons and or similar technologies. Cookies are small text files stored in a user’s browser, and web beacons are electronic images that allow to count visitors who have accessed a particular page and to access certain cookies. Cookies are also normally used by websites that use third party traffic monitoring and tracking services. For further information on Cookies please see our Cookie policy at: <a href="https://influereinvestigations.com/privacy-policy">influereinvestigations.com/privacy-policy</a></p>
                    <p style={{paddingLeft: '25px'}}>1.6. &nbsp;&nbsp;&nbsp;If you wish not to continue to receive cookies from us, with the exception of technical cookies, you may notify us by following the instructions for opting out of such cookies on our Website or sending us an email to: Info@influereinvestigations.com. If you are accessing our Website from the European Economic Area, the use of Cookies, including third party and persistent cookies, shall be made in accordance with the laws applicable to you. In case you have any questions about how we use cookies which are not answered in this statement, you may contact us by sending us an email to: Info@influereinvestigations.com.</p>

                    <br/><br/>
                    <h5><u>3. Sharing Data with Third Parties</u></h5>
                    <p style={{paddingLeft: '25px'}}>3.1. &nbsp;&nbsp;&nbsp;Please note that we do not share any Personally Identifiable Information collected from you with third parties or any of our partners except in the following events and solely to the extent needed:</p>
                    <p style={{paddingLeft: '50px'}}>1.1.1. &nbsp;&nbsp;&nbsp;To provide our users with the Website or Services in accordance with the terms and conditions of this Privacy Policy;</p>
                    <p style={{paddingLeft: '50px'}}>1.1.2. &nbsp;&nbsp;&nbsp;We may share IP addresses collected by us, for the purpose of detecting any fraud, illegality, security risks or other technical issues as related to the Website or Services or as required for the operation and improvement of the Website or Services;</p>
                    <p style={{paddingLeft: '50px'}}>1.1.3. &nbsp;&nbsp;&nbsp; Influere is required or reasonably thinks that it is required by law or law enforcement agencies to share or disclose your information;</p>
                    <p style={{paddingLeft: '50px'}}>1.1.4. &nbsp;&nbsp;&nbsp; In any case of conflict, dispute, or legal proceeding of any kind between users and Influere or between you and other users with respect to, or in relation with the Website or Services;  </p>
                    <p style={{paddingLeft: '50px'}}>1.1.5. &nbsp;&nbsp;&nbsp; In case you have breached our Privacy Policy or Terms, or you have violated your rights to use the Website or Services, or acted in a manner which we reasonably believe to be violating any applicable laws, rules, or regulations;</p>
                    <p style={{paddingLeft: '50px'}}>1.1.6. &nbsp;&nbsp;&nbsp;As required to prevent damage or breach to the rights or interests (including any legal rights) of third parties, including with respect to safety issues of Influere, our users or any applicable third party;</p>
                    <p style={{paddingLeft: '50px'}}>1.1.7. &nbsp;&nbsp;&nbsp;If Influere organizes the structure or business of the Website or Services within a different framework, or through another legal structure or entity, or in case of sale or merger of Influere; and</p>
                    <p style={{paddingLeft: '50px'}}>1.1.8. &nbsp;&nbsp;&nbsp;In certain cases we may disclose Personally Identifiable Information to our trusted partners and service providers so that they can perform requested services on our behalf, such as for fraud detection, server and data storage providers or for security purposes etc.</p>

                    <br/><br/>
                    <h5><u>4. Will Influere transfer my Personally Identifiable Information Internationally? </u></h5>
                    <p style={{paddingLeft: '25px'}}>4.1. &nbsp;&nbsp;&nbsp;We will store and process information in the United States, on our cloud-based services’ sites. From time to time, we will make operational decisions which will have an impact on the sites in which we maintain personally identifiable information. We make sure that our data hosting service providers provide us with adequate confidentiality and security commitment.</p>
                    <p style={{paddingLeft: '25px'}}>4.2. &nbsp;&nbsp;&nbsp;If you are a resident of the European Economic Area or any other jurisdiction where transfer of your Personal Data (as such term is defined in the EU General Data Protection Regulation (the “GDPR”)), to another jurisdiction requires your consent, then you provide us your express and unambiguous consent to such transfer. You can contact us at Info@influereinvestigations.com for further information about data transfer.</p>
                    <p style={{paddingLeft: '25px'}}>4.3. &nbsp;&nbsp;&nbsp;To protect personally identifiable information related to you or your Personally Identifiable Information when such information is transferred to countries outside the European Economic Area and to provide it with adequate level of protection, we have implemented appropriate safeguards in accordance with applicable laws. For example, the transfer of Personal Data to Israel is made in accordance with the EU Commission decision 2011/61/EU of January 31, 2011, on the adequate protection of personally identifiable information by the State of Israel with regard to automated processing of personally identifiable information.</p>
                    <p style={{paddingLeft: '25px'}}>4.4. &nbsp;&nbsp;&nbsp;If any data transfer measure is terminated, revoked or invalidated, or at our discretion, we will use alternative lawful measures to transfer Personal Data, to the extent required under EU data protection laws.</p>

                    <br/><br/>
                    <h5><u>5. EU Related Provisions</u></h5>
                    <p style={{paddingLeft: '25px'}}>5.1. &nbsp;&nbsp;&nbsp;<u>The Lawful Basis Under Which Influere Processes Personal Data.</u></p>
                    <p style={{paddingLeft: '50px'}}>1.1.9. &nbsp;&nbsp;&nbsp;As part of our Privacy Policy we would like to explain to you the lawful basis under which we process Personal Data (as defined below), all subject to the EU General Data Protection Regulation (the “GDPR”), as well as other applicable data privacy regulations, as follows:</p>
                    <p style={{paddingLeft: '100px'}}>(i) &nbsp;&nbsp;&nbsp;Where a user uses our Website or Services, we process Personal Data of such user in order to perform our contract with such user or provide the Services to such user;</p>
                    <p style={{paddingLeft: '100px'}}>(ii) &nbsp;&nbsp;&nbsp;We process Personal Data, as described below, under the lawful basis which relies on our legitimate interests. Such interest include, among others, processing Personal Data of our users: (i) as required for the provision of the Services in accordance with Recital 47 of the GDPR; (ii) as necessary for the purposes of preventing fraud or exploiting or abuse of the Website and any of the Applications or the Services ; (iii) as necessary for the performance direct marketing and communication purposes; (iv) as required to be shared among our affiliated corporate entities for the purpose of the provision of the Services; or (v) as required for the prevention of unauthorized access to electronic communications networks and malicious code distribution and stopping 'denial of service' attacks and damage to computer and electronic communication systems.</p>
                    <p style={{paddingLeft: '100px'}}>(iii) &nbsp;&nbsp;&nbsp;Some Personal Data, such as third-party cookies we use are based on consent provided to us by the users as set forth in our Cookie Policy under <a href="https://influereinvestigations.com/privacy-policy">influereinvestigations.com/privacy-policy</a>; and</p>
                    <p style={{paddingLeft: '100px'}}>(iv) &nbsp;&nbsp;&nbsp;Some of the Personal Data may be processed by our trusted third-party partners, under the lawful basis which relies on our legitimate interests, except where such interests are overridden by the fundamental rights of natural persons which require protection of Personal Data.</p>
                    <p style={{paddingLeft: '25px'}}>1.7. &nbsp;&nbsp;&nbsp;<u>Special Categories of Personal Data. </u></p>
                    <p style={{paddingLeft: '25px'}}>We do not knowingly collect or process any Personal Data included in Special Categories of Personal Data (as defined in the GDPR) In the event you become aware that such data has been posted to the Website or collected by us, please inform us immediately.</p>
                    <p style={{paddingLeft: '50px'}}>5.2. &nbsp;&nbsp;&nbsp;<u>Right of erasure and restriction.</u></p>
                    <p style={{paddingLeft: '50px'}}>You may be entitled to request the erasure or the restriction of your Personal Data, and we will comply with such requests, to the extent required under applicable law.</p>
                    <p style={{paddingLeft: '50px'}}>5.3. &nbsp;&nbsp;&nbsp;<u>Reviewing and amending your Personal Data.</u></p>
                    <p style={{paddingLeft: '50px'}}>You may send us a request in connection with reviewing your Personal Data to: Info@influereinvestigations.com. In the event any Personal Data is incorrect or outdated, you may update and correct such data by providing us with appropriate notification or correcting the data directly on the Site, where possible.</p>
                    <p style={{paddingLeft: '50px'}}>5.4. &nbsp;&nbsp;&nbsp;<u>Retention of Data.</u></p>
                    <p style={{paddingLeft: '50px'}}>We reserve the right to retain any Personal Data for as long as reasonably necessary in order to: (i) fulfill the purposes described herein; (ii) in the defense or assertion of legal claims and liability; (iii) for the analysis and development of the Website or Services; and (iv) to comply with applicable laws, regulations or rules. Non-Personal Data and statistical anonymized data may be retained by us without limitation. </p>
                    <p style={{paddingLeft: '50px'}}>5.5. &nbsp;&nbsp;&nbsp;<u>Portability of Personal Data. </u></p>
                    <p style={{paddingLeft: '50px'}}>To the extent relevant to you and to the Services, you may be entitled to request the portability of your Personal Data, and we will comply with such requests, to the extent required under applicable law.   </p>
                    <p style={{paddingLeft: '50px'}}>5.6. &nbsp;&nbsp;&nbsp;<u>Objection to processing of Personal Data and Profiling. </u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right to object, to or to request restriction, of the processing of your Personal Data and you have the right to object to profiling.</p>
                    <p style={{paddingLeft: '50px'}}>5.7. &nbsp;&nbsp;&nbsp;<u>Withdrawal of Consent.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right to withdraw your consent at any time. Please note that there may be circumstances in which we are entitled to continue processing your data, in particular if the processing is required to meet our legal and regulatory obligations. Also, please note that the withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.</p>
                    <p style={{paddingLeft: '50px'}}>5.8. &nbsp;&nbsp;&nbsp;<u>Cross-Border Data Transfers. </u></p>
                    <p style={{paddingLeft: '50px'}}>You also have a right to request certain details of the basis on which your personal data is transferred outside the European Economic Area, but data transfer agreements and/or other details may need to be partially redacted for reasons of commercial confidentiality.</p>
                    <p style={{paddingLeft: '50px'}}>5.9. &nbsp;&nbsp;&nbsp;<u>Complaints.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have a right to lodge a complaint with your local data protection supervisory authority (i.e., your place of habitual residence, place or work or place of alleged infringement) at any time or before the relevant institutions in your place of residence We ask that you please attempt to resolve any issues with us before you contact your local supervisory authority and/or relevant institution by contacting us at: Info@influereinvestigations.com.</p>
                    <p style={{paddingLeft: '50px'}}>5.10. &nbsp;&nbsp;&nbsp;<u>Cookies Consent withdrawal.</u></p>
                    <p style={{paddingLeft: '50px'}}>If you wish not to continue to receive cookies from us, with the exception of technical cookies, you may notify us by following the instructions for opting out of such cookies on our Website or sending us an email to: Info@influereinvestigations.com. If you are accessing our Website from the European Economic Area, the use of Cookies, including third party and persistent cookies, shall be made in accordance with the laws applicable to you. In case you have any questions about how we use cookies which are not answered in this statement, you may contact us by sending us an email to: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</p>

                    <br/><br/>
                    <h5><u>6. California Residents related provisions</u></h5>
                    <p>This section of our Privacy Policy applies to you, if you are a California resident.</p>
                    <p style={{paddingLeft: '25px'}}>6.1. &nbsp;&nbsp;&nbsp;<u>Your Rights as a California Resident.</u></p>
                    <p style={{paddingLeft: '25px'}}>You are entitled to the following specific rights under the California Consumer Privacy Act in relation to your personally identifiable information:</p>
                    <p style={{paddingLeft: '50px'}}>1.1.10. &nbsp;&nbsp;&nbsp;<u>Access to Specific Information and Data Portability Rights.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right to request that we will disclose certain information to you about our collection and use of your personally identifiable information over the past 12 months. After verifying your request, we will disclose to you:</p>
                    <p style={{paddingLeft: '100px'}}>(i) &nbsp;&nbsp;&nbsp;The categories of personally identifiable information that we collected about you;</p>
                    <p style={{paddingLeft: '100px'}}>(ii) &nbsp;&nbsp;&nbsp;The categories of sources for the personally identifiable information that we collected about you; and</p>
                    <p style={{paddingLeft: '100px'}}>(iii) &nbsp;&nbsp;&nbsp;Our business or commercial purpose for collecting or selling personally identifiable information related to you.</p>
                    <p style={{paddingLeft: '100px'}}>(iv) &nbsp;&nbsp;&nbsp;The categories of personally identifiable information that we disclosed for a business purpose or sold, and the categories of third parties to whom we disclosed or sold that particular category of personally identifiable information;</p>
                    <p style={{paddingLeft: '100px'}}>(v) &nbsp;&nbsp;&nbsp;The specific pieces of personally identifiable information that we have collected about you;</p>
                    <p style={{paddingLeft: '100px'}}>(vi) &nbsp;&nbsp;&nbsp;If we sold or disclosed personally identifiable information related to you for a business purpose, we will provide you with two separate lists which will identify the personally identifiable information categories that each category of recipient purchased or obtained.</p>
                    <p style={{paddingLeft: '50px'}}>6.2. &nbsp;&nbsp;&nbsp;<u>Deletion Rights.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right to request that we delete personally identifiable information related to you that we collected from you. After verifying your request, we will delete the personally identifiable information that you have requested to delete from our records, unless an exception applies (such as if we are required to maintain records in accordance with applicable law).</p>
                    <p style={{paddingLeft: '50px'}}>6.2. &nbsp;&nbsp;&nbsp;<u>The Right to Opt-Out.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right to opt out of the sale of personally identifiable information related to you.
                        For a period of 12 months following the execution of your request to opt out, we will not ask your permission to sell personally identifiable information related to you.</p>
                    <p style={{paddingLeft: '50px'}}>6.4. &nbsp;&nbsp;&nbsp;<u>Right to Non-Discrimination.</u></p>
                    <p style={{paddingLeft: '50px'}}>You have the right not to receive discriminatory treatment by us for the exercise of the privacy rights described above.</p>
                    <p style={{paddingLeft: '50px'}}>6.5. &nbsp;&nbsp;&nbsp;<u>Exercising Your Rights.</u></p>
                    <p style={{paddingLeft: '100px'}}>1.1.11 &nbsp;&nbsp;&nbsp;To exercise the access, data portability, and deletion rights described above, please submit your request to us by sending us a detailed message to: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.12 &nbsp;&nbsp;&nbsp;To exercise your right to opt out of the sale of personally identifiable information related to you, please submit your request to us by sending us a detailed message to: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.13 &nbsp;&nbsp;&nbsp;Only you or a person authorized to act on your behalf, may make a request related to personally identifiable information. You may also make a request on behalf of your minor child.
                    </p>
                    <p style={{paddingLeft: '100px'}}>1.1.14 &nbsp;&nbsp;&nbsp;A request for access can be made by you only twice within a 12-months period.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.15 &nbsp;&nbsp;&nbsp;We cannot respond to your request or provide you with the requested personally identifiable information if we cannot verify your identity or authority to make the request and confirm the personally identifiable information that relates to you. We will only use the personally identifiable information provided in your request to verify your identity or authority to make the request.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.16 &nbsp;&nbsp;&nbsp;We will do our best to respond to your request within 45 days. If we need more time (up to additional 45 days), we will inform you of the reason and extension period in writing. We will deliver our written response by email.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.17 &nbsp;&nbsp;&nbsp;Any disclosures that we provide will only cover the 12-months period preceding receipt of your request.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.18 &nbsp;&nbsp;&nbsp;The response we provide will also explain the reasons for our inability to comply with your request, if applicable.</p>
                    <p style={{paddingLeft: '100px'}}>1.1.19 &nbsp;&nbsp;&nbsp;We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will inform you of the reasons for such decision and provide you with a cost estimate before processing further your request.</p>

                    <br/><br/>
                    <h5><u>7. Protection of Personal Data</u></h5>
                    <p style={{paddingLeft: '25px'}}>7.1. &nbsp;&nbsp;&nbsp;We use industry standard tools and security measures to prevent unauthorized access or disclosure of users’ Personally Identifiable Information including unlawful destruction or accidental loss, as is acceptable in the industry. In the event you believe that your privacy rights or security is not treated in accordance with this Privacy Policy, or you have reasonable concerns with respect to our Service, please contact us at: Info@influereinvestigations.com. </p>
                    <p style={{paddingLeft: '25px'}}>7.2. &nbsp;&nbsp;&nbsp;Although we strive to use our best efforts to protect Personally Identifiable Information, please be aware that these security measures cannot fully eliminate security risks associated with Personally Identifiable Information. Therefore, while Influere strives to use commercially acceptable means to protect Personally Identifiable Information, we cannot guarantee its absolute security, and therefore, we shall not be liable for any damages caused by unauthorized access to your Personally Identifiable Information, unless prohibited by applicable laws and regulations.</p>
                    <p style={{paddingLeft: '25px'}}>7.3. &nbsp;&nbsp;&nbsp;Please note that in the event of a security incident, in which we discover that your Personally Identifiable Information may be at risk, we will take reasonable efforts to notify you and the applicable authority (if required, subject to applicable laws and regulations).</p>

                    <br/><br/>
                    <h5><u>8. Will I Receive Promotional Materials from Influere? </u></h5>
                    <p>We do not send users any commercial or promotion information via direct mailing such as emails or SMS messaging without obtaining the users’ explicit prior consent. You may unsubscribe from receiving such communications at any time, by sending us a notification to: Info@influereinvestigations.com, or following the instructions for unsubscribing included in such communications.</p>

                    <br/><br/>
                    <h5><u>9. Changes to The Privacy Policy</u></h5>
                    <p style={{paddingLeft: '25px'}}>9.1. &nbsp;&nbsp;&nbsp;Please be advised that we reserve the right to change this Privacy Policy from time to time and we will post the amended terms on the Website or Services and make reasonable efforts to notify you by email of material changes. The last revision will be reflected in the “Last Revised” date above. Amended terms will take effect immediately. Therefore, you should review the Privacy Policy periodically in order to remain informed of any changes. You agree to accept the posting of a revised Privacy Policy as actual notice to you.</p>
                    <p style={{paddingLeft: '25px'}}>9.2. &nbsp;&nbsp;&nbsp;Your continued use of the Website or Services after any changes to this Privacy Policy have been posted as of the “Last Revised” date above indicates your acceptance of those changes. If you do not agree to the revised Privacy Policy, please refrain from using our Website or Services and contact us by sending an email to: Info@influereinvestigations.com.</p>

                    <br/><br/>
                    <h5><u>10. Persons Under 18</u></h5>
                    <p>Our Website and Services are intended for a general audience, which is not directed to persons under 18 years old. If a parent or guardian becomes aware that his/her child has provided us with Personally Identifiable Information, he/she should contact us immediately. We do not knowingly collect or solicit Personally Identifiable Information from people under 18 years old. If we become aware that a person under 18 years old has provided us with Personally Identifiable Information, we will delete such data from our databases. If you are aware that your child has provided us with Personally Identifiable Information, please contact us at: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a>.</p>

                    <br/><br/>
                    <h5><u>11. Links to Third Party Websites/Applications</u></h5>
                    <p>Please note that our Website or Services may link to other websites or applications of third parties that provide useful information/services. Any information users provide on the linked pages is provided directly to this third party and is subject to that third party’s privacy policy. We are not responsible for the content or privacy practices of websites which are linked via the Website. Links from our Website to third parties or other sites are provided for users’ convenience. You should learn about the privacy practices of each website before providing them with Personally Identifiable Information.</p>

                    <br/><br/>
                    <h5><u>12. Applicable Laws</u></h5>
                    <p style={{paddingLeft: '25px'}}>12.1. &nbsp;&nbsp;&nbsp;Please note that the provisions included in this Privacy Policy relating to matters regulated under the GDPR will apply only to the processing of Personally Identifiable Information which is subject to the GDPR in accordance with the applicability of the provisions contained therein. If the GDPR does not apply to the processing of your Personally Identifiable Information, some of the provisions above may not apply to you.</p>
                    <p style={{paddingLeft: '25px'}}>12.2. &nbsp;&nbsp;&nbsp;Please note that the provisions included in this Privacy Policy relating to matters regulated under the CCPA will apply only to the processing of Personally Identifiable Information which is subject to the CCPA in accordance with the applicability of the provisions contained therein. If the CCPA does not apply to the processing of your Personally Identifiable Information, some of the provisions above may not apply to you.</p>
                    <p style={{paddingLeft: '25px'}}>12.3. &nbsp;&nbsp;&nbsp;Please note that the provisions included in this Privacy Policy relating to matters regulated under the United Kingdom’s Data Protection Act 2018 and any other data protection laws substantially amending, replacing or superseding the GDPR following the exit by the United Kingdom from the European Union, and the data protection or privacy laws of any other country including, without limitation, the Israeli Protection of Privacy Law 5741-1981 and any regulations enacted thereunder including the Privacy Protection Regulations (Transfer of Data to Databases Abroad), 5761-2001 and Privacy Protection Regulations (Data Security), 5777-2017, and any guidelines and/or instructions published by the Israeli Privacy Protection Authority will apply only to the processing of Personally Identifiable Information which is subject to the aforementioned legislation, if the aforementioned legislation does not apply to the processing of your Personally Identifiable Information, some of the provisions above may not apply to you as well.</p>

                    <br/><br/>
                    <h5><u>13. Questions or Concerns Regarding Privacy</u></h5>
                    <p style={{paddingLeft: '25px'}}>13.1. &nbsp;&nbsp;&nbsp;If you have any questions or concerns regarding privacy issues, please send us a detailed message to: <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a> and we will make every effort to resolve your concerns without delay.</p>
                    <p style={{paddingLeft: '25px'}}>13.2. &nbsp;&nbsp;&nbsp;You are also entitled to file a complaint with the appropriate supervisory authority in connection with concerns you may have with regards to your privacy and Personally Identifiable Information.</p>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default PrivacyPolicy

export const Head = () => <title>Influere Investigations | Privacy Policy</title>